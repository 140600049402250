import React from 'react';

import DocsLayout from '../components/DocsLayout';

const DocsPage = () => {
  return (
    <DocsLayout hasSidebar>
      <p>Getting started with Pushback is simple.</p>
    </DocsLayout>
  );
};

export default DocsPage;
